<template>
  <div v-if="currentCity" :class="!isVerticalPosition ? sectionStyles : ''">
    <div :class="[classes.wrapper, 'container']">
      <div
        v-if="!hasHiddenTitle"
        :class="[sectionTitleLargeStyles, '!mb-2', 'hidden', 'xl:flex']"
      >
        {{ currentCity.services_count }}
        {{
          declension(currentCity.services_count, [
            'филиал',
            'филиала',
            'филиалов',
          ])
        }}
        в
        <BaseButton
          :class="[sectionTitleLargeStyles, '!mb-0', 'ml-2', linkStyles]"
          @click="openModalShowCities"
        >
          {{ currentCity.name_locative }}
        </BaseButton>
      </div>
      <div
        v-if="!hasHiddenSubtitle"
        :class="[classes.subtitle, 'hidden', 'xl:block']"
      >
        Приходите к нам лично и убедитесь в качестве наших смартфонов
      </div>
      <div
        :class="[
          classes.content,
          isVerticalPosition ? classes.contentVertical : '',
        ]"
      >
        <div>
          <div
            :class="[
              classes.contentPanelList,
              !isVerticalPosition ? 'hidden xl:block' : '',
            ]"
          >
            <div
              v-for="(serviceCenter, index) in serviceCenters"
              :key="index"
              :class="classes.contentPanelItem"
            >
              <Icon
                name="common:location"
                height="14"
                class="!text-icon-yellow mt-2"
              />
              <div class="ml-[15px]">
                <div class="mb-1">
                  <span class="text-primary font-bold">
                    {{ serviceCenter.anchor }}
                  </span>
                  <br>
                  г. {{ serviceCenter.city }}, {{ serviceCenter.address }}
                </div>
                <BaseButton
                  intent="link"
                  underline="on"
                  @click="showServiceCenter(serviceCenter)"
                >
                  Подробнее
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
        <div v-if="serviceCenters" class="max-w-[100vw] overflow-hidden">
          <WidgetMap
            :has-modal="true"
            @service-center-selected="showServiceCenter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useModal } from 'vue-final-modal'
import type { ServiceCenter } from '~/types/models'
import ModalShowServiceCenter from '@/components/modal/ShowServiceCenter.vue'
import ModalShowCities from '@/components/modal/ShowCities.vue'
import { sectionStyles, sectionTitleLargeStyles, declension, linkStyles } from '@/utils'

const props = defineProps({
  position: {
    type: String,
    default: 'horizontal', // or "vertical"
  },
  hasHiddenTitle: {
    type: Boolean,
    default: false,
  },
  hasHiddenSubtitle: {
    type: Boolean,
    default: false,
  },
})

const classes = {
  wrapper: 'relative',
  subtitle: cx(['mb-5', 'xl:mb-[34px]']),
  content: cx([
    'grid',
    'grid-rows-[auto]',
    'xl:grid-cols-[350px_auto]',
    'xl:grid-rows-none',
  ]),
  contentVertical: cx([
    'xl:!grid-rows-[repeat(2,_auto)]]',
    'xl:!grid-cols-none',
  ]),
  contentPanelList: cx([
    'max-h-[200px]',
    'overflow-y-auto',
    'mb-[30px]',
    'xl:max-h-[360px]',
    'xl:mb-0',
    'xl:mr-[15px]',
  ]),
  contentPanelItem: cx(['flex', 'items-start', 'mb-6']),
}

const isVerticalPosition = computed(() => props.position === 'vertical')

const citiesStore = useCitiesStore()
const { current: currentCity } = storeToRefs(citiesStore)

const serviceCentersStore = useServiceCentersStore()
const { list: serviceCenters } = storeToRefs(serviceCentersStore)

const { open: openModalShowCities, close: closeModalShowCities } = useModal({
  component: ModalShowCities,
  attrs: {
    onChangeCity() {
      citiesStore.confirm()
      closeModalShowCities()
    },
    onClosed() {
      citiesStore.confirm()
    },
  },
})

const { open: openModalShowServiceCenter } = useModal({
  component: ModalShowServiceCenter,
})

const showServiceCenter = (serviceCenter: ServiceCenter) => {
  serviceCentersStore.shownInModal = serviceCenter
  openModalShowServiceCenter()
}
</script>